import { MenuEntry } from '@pancakeswap-libs/uikit'
import { BsCoin, BsTwitter, BsFacebook, BsYoutube, BsReddit, BsInstagram, BsTelegram } from 'react-icons/bs'
// import {  BsCoin } from "react-icons/fa";
import { GiToken } from "react-icons/gi";

const config: MenuEntry[] = [
  // {
  //   label: 'Home',
  //   icon: 'HomeIcon',
  //   href: 'https://blockchain-farm.mobiloitte.org/',
  // },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      },
    ],
  },
  {
    label: 'Stake',
    icon: BsCoin,
    href: 'https://staking.qidex.site/stake-details',
  },
  {
    label: 'Token Creator',
    icon: GiToken,
    href: 'https://staking.qidex.site/token-creator',
  },
]

export default config

export const socialsLinks:any = [

  {
    label: 'Telegram',
    icon: BsTelegram,
    href: 'https://t.me/HovRonQiblockchain',
  },
  
  {
    label: 'Twitter',
    icon: BsTwitter,
    href: 'https://twitter.com/dex_qi',
  },
  {
    label: 'Facebook',
    icon: BsFacebook,
    href: 'https://www.facebook.com/profile.php?id=100087150605472',
  },
  {
    label: 'Instagram',
    icon: BsInstagram,
    href: 'https://www.instagram.com/qidex.site/',
  },
  // {
  //   label: 'Youtube',
  //   icon: BsYoutube,
  //   href: 'https://www.youtube.com/watch?v=CMqRqd97rQg',
  // },
]
